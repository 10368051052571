import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Dialog, DialogContent, Grid, Typography, LinearProgress, List, Menu, MenuItem } from '@material-ui/core';
import { useSelector } from 'react-redux';
import SelectorContextProvider, { SelectorContext } from 'fitbud/components/catalogSelector/selectorContext';
import SelectorHeader from 'fitbud/components/catalogSelector/selectorHeader';
import SelectedList from 'fitbud/components/catalogSelector/selectedList';
import { ClrdButton } from 'fitbud/components/form-fields';
import { DumbList } from 'fitbud/components/catalog/index';
import useDebounce from 'fitbud/hooks/useDebounce';
import _ from 'lodash';
import { LEGACY_SERVICE_BASE } from 'fitbud/providers/access-provider';

const LEGACYID = 'vidcall_';
const LEGACY = {_id: LEGACYID, data: LEGACY_SERVICE_BASE};

const SelectorList = (props) => {
  const { onClick, docs, loading, parseListData, legacyService = false } = props;
  const { legacy } = useSelector((s) => s.services);
  const [legacyPicker, setLegacyPicker] = useState(false);
  const { query } = useContext(SelectorContext);
  const debouncedQuery = useDebounce(query, 500);
  const N = (docs || []).length;
  const searchLocations = useMemo(() => {
    const q = (debouncedQuery || '').trim().toLocaleLowerCase();
    if (!q) {
      if (legacyService) return [...docs, LEGACY];
      return docs;
    }
    return _.filter(docs, (doc) => _.get(doc, 'data.title', '').toLocaleLowerCase().includes(q) || _.get(doc, 'data.name', '').toLocaleLowerCase().includes(q));
  }, [debouncedQuery, docs, legacyService]);

  const handleSelect = (e, doc) => {
    if (doc._id === 'vidcall_') {
      return setLegacyPicker(e.currentTarget);
    }
    onClick(doc);
  };
  const closeLegacyPicker = () => setLegacyPicker(false);
  const chooseLegacy = (e) => {
    const value = e.target.dataset.value;
    closeLegacyPicker();
    handleSelect(null, {_id: LEGACYID + value, data: {...LEGACY_SERVICE_BASE, duration: value}});
  };

  return (
    <div className="flex-1 d-flex flex-column position-relative overflow-x-hidden">
      {loading && <LinearProgress style={{ zIndex: 200 }} className="position-absolute w-100 " />}
      {!loading && !!N && (
        <List disablePadding className="overflow-auto flex-1 bg-white">
          <DumbList docs={searchLocations} onClick={handleSelect} parseListData={(l) => parseListData(l)} />
        </List>
      )}
      {legacyService && <Menu keepMounted anchorEl={legacyPicker} open={Boolean(legacyPicker)}
        onClose={closeLegacyPicker} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
        <MenuItem disabled>Select Preferred Duration</MenuItem>
        {legacy.map(x => {
          const {duration, status} = x;
          if (status !== 'active') return null;
          return <MenuItem key={duration} onClick={chooseLegacy} data-value={duration}>{duration} mins</MenuItem>
        })}
      </Menu>}
    </div>
  );
};

const CatalogMultiSelectorContainer = (props) => {
  const { keyName = 'meals', hidePreSelected, legacyService = false, docs, selectPlaceholderTxt, emptyPlaceholderTxt, parseListData } = props;
  const { addSelectedValues } = useContext(SelectorContext);
  const _placeholder = useMemo(() => {
    if (selectPlaceholderTxt && emptyPlaceholderTxt)
      return { empty: emptyPlaceholderTxt, select: selectPlaceholderTxt };
    if (keyName === 'locations') return { empty: 'No Locations Found', select: 'Select Location from left Panel' };
    if (keyName === 'groupClass') return { empty: 'No Group Class Found', select: 'Select Group Class from left Panel' };
    if (keyName === 'services') return { empty: 'No 1 on 1 Sessions Found', select: 'Select 1 on 1 Sessions from left Panel' };
    if (keyName === 'trainers') return { empty: 'No Trainers Found', select: 'Select Trainers from left Panel' };
    else return { empty: 'Not Found', select: 'Please Select from left.' };
  }, [selectPlaceholderTxt, emptyPlaceholderTxt, keyName]);

  const selectPlaceholder = () => {
    return (
      <div className="flex-grow-1 h-100 fp-20 text-center d-flex align-items-center justify-content-center flex-column ">
        <Typography className="fmt-25 font_15_700">{_placeholder.empty}</Typography>
        <Typography className="fmt-10" style={{ fontSize: '13px', fontWeight: 400, color: '#37404D' }}>
          {_placeholder.select}
        </Typography>
      </div>
    );
  };

  return (
    <DialogContent className="d-flex fp-0">
      <Grid container className="flex-grow-1">
        <Grid
          item
          xs={7}
          className="h-100 d-flex position-relative"
          style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
          <SelectorList onClick={addSelectedValues} legacyService={legacyService} docs={docs} parseListData={parseListData} />
        </Grid>
        <Grid className="h-100 d-flex" item xs={5}>
          <SelectedList keyName={keyName} legacyService={legacyService}
          noSelectedPlaceholder={selectPlaceholder} hidePreSelected={hidePreSelected} parseListData={parseListData} />
        </Grid>
      </Grid>
    </DialogContent>
  );
};

const Header = (props) => {
  const { handleAdd, selected, keyName } = props;
  const { selectedValues } = useContext(SelectorContext);
  const { addAllSelectedValues } = useContext(SelectorContext);
  const totalSelectedValues = (selectedValues || []).length;

  useEffect(() => {
    if (!!selected && !!selected.length) {
      addAllSelectedValues(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const handleSave = () => {
    handleAdd && handleAdd(selectedValues);
  };

  const getHeaderActions = () => {
    return (
      <ClrdButton
        disabled={!totalSelectedValues}
        variant="contained"
        color="primary"
        className="f-medium"
        onClick={handleSave}>
        Save
      </ClrdButton>
    );
  };
  return <SelectorHeader hideLibrarySwitch={true} keyName={keyName} headerActions={getHeaderActions} {...props} />;
};

const CommonMultiSelector = (props) => {
  const { open, item, hidePreSelected = 0, keyName } = props;
  return (
    <Dialog
      open={open}
      scroll="paper"
      classes={{
        paper: 'width-800 height-70',
      }}
      id={props?.id || 'multiple_selector'}>
      <SelectorContextProvider hidePreSelected={hidePreSelected} item={item} keyName={keyName}>
        <Header {...props} />
        <CatalogMultiSelectorContainer {...props} />
      </SelectorContextProvider>
    </Dialog>
  );
};

export default CommonMultiSelector;
