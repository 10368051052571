import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import Dialog from 'fitbud/components/Dialog';
import { FormTextField } from 'fitbud/components/form-fields';
import ImageFileUpload from 'fitbud/components/imageSelector';
import DropDownIcon from '@material-ui/icons/ExpandMore';
import React, { useContext, useMemo, useState } from 'react';
import { FREQUENCY, DAY, INF } from './constant';
import { DateTimePicker } from '@material-ui/pickers';
import ArrowIcon from '@material-ui/icons/KeyboardArrowDown';
import { ChevronRightRounded } from '@material-ui/icons';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { CloseRounded } from '@material-ui/icons';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import appRdxFns from 'fitbud/redux/app';
import notificationRepo from 'fitbud/repo/inAppNotification';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { parsePack } from '../plan/helper';
import { DEFAULT_ERROR, ERROR_URL } from 'fitbud/utils/constants';
import * as Sentry from '@sentry/browser';
import PaymentPlanSelector from 'fitbud/components/paymentPlanSelector';
import { isValidURL } from 'fitbud/utils/helpers';
import _ from 'lodash';
import CatalogSingleSelector from 'fitbud/components/catalogSelector/catalogSingleSelector';

const PRIMARY_CTA_INFO =
  'Set the primary call-to-action to navigate to plans or an external link in your in-app notifications.';
const SECONDARY_CTA_INFO = 
  'Secondary call-to-action works just like Primary CTA but with a subtle appearance.'

const DEFAULT_DATA = {
  title: null,
  referenceTitle: null,
  description: null,
  image: null,
  frequency: 1,
  start: moment(),
  end: undefined,
  ctaLabel1: null,
  ctaAction1: 'custom',
  ctaOpen1: null,
  ctaLabel2: null,
  ctaAction2: 'custom',
  ctaOpen2: null,
  archive: false,
  active: true,
};

const DEFAULT_DIALOG = {
  open: false,
  cta: null,
};

function formatDateAndTime(value) {
  if (moment.isMoment(value)) {
    return value.format('YYYYMMDDHHmm');
  }
  return INF;
}

function InAppNotificationDialog({ initialData = {}, onEdit: handleEdit, id, open, onClose: handleClose, onSave }) {
  const { cta1, cta2, _rules, _from, _till, title_ref, ...restInitialData } = initialData;

  const initialDataState =
    Object.keys(initialData).length > 0
      ? {
          ...restInitialData,
          ctaAction1: cta1?._action || 'custom',
          ctaLabel1: cta1?.label,
          ctaOpen1: cta1?.open,
          ctaAction2: cta2?._action || 'custom',
          ctaLabel2: cta2?.label,
          ctaOpen2: cta2?.open,
          frequency: (_rules?.minutes || DAY) / DAY,
          start: moment(_from, 'YYYYMMDDHHmm'),
          end: _till && _till !== INF &&  moment(_till, 'YYYYMMDDHHmm').isValid() ? moment(_till, 'YYYYMMDDHHmm') : undefined,
          referenceTitle: title_ref,
        }
      : DEFAULT_DATA;

  const [data, setData] = useState(initialDataState);
  const { cid, comp } = useContext(FirebaseAuthContext);

  const history = useHistory();
  const dispatch = useDispatch();
  const { showLoader, hideLoader } = appRdxFns(dispatch);
  const { enqueueSnackbar } = useSnackbar();
  const [paymentDialog, setPaymentDialog] = useState(DEFAULT_DIALOG);
  const [challengeDialog, setChallengeDialog] = useState(DEFAULT_DIALOG);
  const [error, setError] = useState({});
  const [dirty, setDirty] = useState(false);

  const actions = useMemo(() => {
    const company = comp ? comp.data() : {};
    let out = [
      { name: 'All Plans Page', link: `https://${cid}.fitbudd.com/packs`, key: 'allPlans' },
      { name: 'Specific Payment Plan', key: 'paymentPlan' },
      { name: 'Challenge', key: 'challenges' },
    ];
    if (company && company._next && company.explore_config) {
      const explore = _.chain(company.explore_config)
        .toPairs()
        .orderBy((x) => x[1].position)
        .map((x) => ({ name: x[1].title, link: `https://${cid}.fitbudd.com/explore/${x[0]}`, key: x[0] }))
        .value();
      out = out.concat(explore);
    }
    out = out.concat([
      { name: 'Messages', key: 'messages', link: `https://${cid}.fitbudd.com/app/chat` },
      { name: 'Custom Link', key: 'custom' },
    ]);
    return out;
  }, [comp, cid]);

  const {
    title,
    referenceTitle,
    description,
    image,
    frequency,
    start,
    end,
    ctaAction1,
    ctaLabel1,
    ctaOpen1,
    ctaAction2 = 'custom',
    ctaLabel2,
    ctaOpen2,
  } = data;
  const isNew = id === 'new';
  const openPrimaryCta = !isNew && ctaLabel1 && ctaAction1 && ctaOpen1;
  const [primaryCta, setPrimaryCta] = useState(openPrimaryCta);
  const openSecondaryCta = !isNew && ctaLabel2 && ctaAction2 && ctaOpen2;
  const [secondaryCta, setSecondaryCta] = useState(openSecondaryCta);

  function openPaymentDialog(name) {
    setPaymentDialog({
      open: true,
      cta: name,
    });
  }

  function openChallengeDialog(name) {
    setChallengeDialog({
      open: true,
      cta: name,
    });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData((prevData) => ({ ...prevData, [name]: value }));
    setDirty(true);
    setError((prevError) => ({ ...prevError, [name]: '' }));
  };

  const handleActionChange = (e) => {
    const { name, value } = e.target;

    const payload = {
      [name]: value,
    };

    if (value === 'paymentPlan') {
      if (name === 'ctaAction1') {
        payload.ctaOpen1 = null;
      } else if (name === 'ctaAction2') {
        payload.ctaOpen2 = null;
      }
      openPaymentDialog(name);
    } else if (value === 'challenges') {
      if (name === 'ctaAction1') {
        payload.ctaOpen1 = null;
      } else if (name === 'ctaAction2') {
        payload.ctaOpen2 = null;
      }
      openChallengeDialog(name);
    } else if (value === 'custom') {
      if (name === 'ctaAction1') {
        payload.ctaOpen1 = null;
      } else if (name === 'ctaAction2') {
        payload.ctaOpen2 = null;
      }
    } else {
      const currentAction = actions.find(({ key }) => key === value);

      const currentOpen = {
        id: value,
        title: currentAction.name,
        link: currentAction.link,
      };
      if (name === 'ctaAction1') {
        payload.ctaOpen1 = currentOpen;
      } else if (name === 'ctaAction2') {
        payload.ctaOpen2 = currentOpen;
      }
    }
    setData((prevData) => ({ ...prevData, ...payload }));
    setDirty(true);
    setError((prevError) => ({ ...prevError, [name]: '' }));
  };
  
  function handleCtaChange(e) {
    const { name, checked } = e.target;

    if (name === 'primary-cta') {
      setPrimaryCta(checked);
    } else {
      setSecondaryCta(checked);
    }
  }

  function handlePlanSubmit(plan) {
    let ctaOpen;
    if (paymentDialog.cta === 'ctaAction2') {
      ctaOpen = 'ctaOpen2';
    } else if (paymentDialog.cta === 'ctaAction1') {
      ctaOpen = 'ctaOpen1';
    }
    const ctaPlan = {
      title: plan.data.title,
      id: plan._id,
      link: `https://${cid}.fitbudd.com/packs/${plan._id}`,
    };
    setData((prevData) => ({ ...prevData, [ctaOpen]: ctaPlan }));
    setError((prevError) => ({ ...prevError, [ctaOpen]: '' }));
    setPaymentDialog(DEFAULT_DIALOG);
  }

  function handleChallengeAdd(challenge) {
    let ctaOpen;
    if (challengeDialog.cta === 'ctaAction2') {
      ctaOpen = 'ctaOpen2';
    } else if (challengeDialog.cta === 'ctaAction1') {
      ctaOpen = 'ctaOpen1';
    }
    const ctaChallenge = {
      title: challenge.data.title,
      id: challenge._id,
      link: `https://${cid}.fitbudd.com/challenge/${challenge._id}`,
    };
    setData((prevData) => ({ ...prevData, [ctaOpen]: ctaChallenge }));
    setError((prevError) => ({ ...prevError, [ctaOpen]: '' }));
    setChallengeDialog(DEFAULT_DIALOG);
  }

  const handleTimeChange = (time, type) => {
    const payload = {
      [type]: time,
    };
    setData((prevData) => ({ ...prevData, ...payload }));
    setError((prevError) => ({ ...prevError, [type]: '' }));
  };

  const resetTimeField = (field) => {
    setData((prevData) => ({ ...prevData, [field]: undefined }));
  };

  const isValid = () => {
    let validation = true;
    const error = {};
    if (!title) {
      validation = false;
      error.title = 'Heading is required';
    }
    if (!referenceTitle) {
      validation = false;
      error.referenceTitle = 'Reference Title is required';
    }
    if(primaryCta && (ctaLabel1 || ctaOpen1)) {
      if (ctaAction1 === 'custom') {
        if (!isValidURL(ctaOpen1)) {
          validation = false;
          error.ctaOpen1 = ERROR_URL;
        }
      }
      if (!ctaLabel1) {
        validation = false;
        error.ctaLabel1 = 'CTA text is required';
      }
      if (!ctaOpen1) {
        validation = false;
        error.ctaOpen1 = 'CTA open is required';
      }
    }
    if (secondaryCta && (ctaLabel2 || ctaOpen2)) {
      if (ctaAction2 === 'custom') {
        if (!isValidURL(ctaOpen2)) {
          validation = false;
          error.ctaOpen2 = ERROR_URL;
        }
      }
      if (!ctaLabel2) {
        validation = false;
        error.ctaLabel2 = 'CTA text is required';
      }
      if (!ctaOpen2) {
        validation = false;
        error.ctaOpen2 = 'CTA open is required';
      }
    }
    if (!moment(initialDataState.start).isSame(start, 'minute') && start.isBefore(moment())) {
      validation = false;
      error.startTime = 'Incorrect start time';
    }
    if(!end) {
      validation = false;
      error.endTime = 'End Date is required'
    } else if (end.isBefore(start)) {
      validation = false;
      error.endTime = 'Incorrect end time';
    }
    setError(error);
    return validation;
  };

  const handleSave = async () => {
    if (!isValid()) return;
    showLoader();
    const {
      start,
      end,
      ctaAction1,
      ctaLabel1,
      ctaOpen1,
      ctaAction2,
      ctaLabel2,
      ctaOpen2,
      frequency,
      referenceTitle,
      ...restData
    } = data;
    const startDateAndTime = formatDateAndTime(start);
    const endDateAndTime = formatDateAndTime(end);
    const minutes = frequency * DAY;
    const times = end ? Math.ceil(end.diff(start, 'days') / frequency) : 9999;

    const payload = {
      ...restData,
      _from: startDateAndTime,
      _till: endDateAndTime || INF,
      cta1: null,
      cta2: null,
      _rules: { times: Math.max(1, times), minutes },
      title_ref: referenceTitle,
    };

    if(primaryCta && ctaOpen1 && ctaLabel1) {
      let openLink = ctaOpen1;
      if(typeof openLink === 'string' && !openLink.startsWith('http')) {
        openLink = 'http://' + ctaOpen1
      }
      let action = openLink;
      if(typeof openLink.link === 'string'){
        action = openLink.link
      }
      payload.cta1 = {
        _action: ctaAction1,
        action,
        label: ctaLabel1,
        open: openLink,
      }
    }

    if (secondaryCta && ctaOpen2 && ctaLabel2) {
      let openLink = ctaOpen2;
      if(typeof openLink === 'string' && !openLink.startsWith('http')) {
        openLink = 'http://' + ctaOpen2;
      }
      let action = openLink;
      if(typeof openLink.link === 'string'){
        action = openLink.link;
      }
      payload.cta2 = {
        _action: ctaAction2,
        action,
        label: ctaLabel2,
        open: openLink,
      };

    }

    try {
      const doc = isNew
        ? await notificationRepo(cid).create(payload, enqueueSnackbar)
        : await notificationRepo(cid).update(id, payload, enqueueSnackbar);
      setDirty(false);
      hideLoader();
      if (!doc) return;
      onSave();
      if (isNew) {
        history.replace(`/messages/in-app-notifications/${doc.id}`);
      } else {
        handleEdit(parsePack(doc).data);
        handleClose();
      }
    } catch (error) {
      hideLoader();
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
      Sentry.captureException(error);
    }
  };

  return (
    <>
      <Dialog
        title={`${isNew ? 'Add' : 'Edit'} In-App Notification`}
        open={open}
        actionText="Save"
        onSave={handleSave}
        onClose={handleClose}
        paperClass="width-600"
        isDirty={dirty}
      >
        <Grid container className="fpx-20 fpt-20">
          <Grid item container xs alignItems="flex-end" className="fmb-20">
            <Grid item xs={12}>
              <FormTextField label="Heading Text" fullWidth required>
                <TextField
                  id="heading-text"
                  variant="outlined"
                  name="title"
                  value={title}
                  onChange={handleChange}
                  error={!!error.title}
                  helperText={error.title}
                  InputProps={{
                    classes: {
                      root: 'medium',
                      input: 'size_16_500 medium',
                    },
                  }}
                />
              </FormTextField>
            </Grid>
            <Grid item xs={12}>
              <FormTextField label="Reference Title" fullWidth required classes={{ control: 'fmb-0' }}>
                <TextField
                  id="reference-title"
                  variant="outlined"
                  value={referenceTitle}
                  name="referenceTitle"
                  onChange={handleChange}
                  error={!!error.referenceTitle}
                  helperText={error.referenceTitle}
                  InputProps={{
                    classes: {
                      root: 'medium',
                      input: 'size_16_500 medium',
                    },
                  }}
                />
              </FormTextField>
            </Grid>
          </Grid>
          <Grid item className="fmb-20">
            <ImageFileUpload
              thumbnail={image}
              id="image"
              name="image"
              onChange={handleChange}
              size="medium"
              className="ml-20"
              aspectRatio={1.5}
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextField label="Description" fullWidth>
              <TextField
                id="description"
                variant="outlined"
                value={description}
                name="description"
                onChange={handleChange}
                multiline
                minRows={2}
                InputProps={{
                  classes: {
                    input: 'size_16_500',
                  },
                }}
              />
            </FormTextField>
          </Grid>
        </Grid>
        <Divider classes={{ root: 'height-2' }} style={{ backgroundColor: '#D8DCE0' }} />
        <Box className="fpx-20 fpt-20 fpb-10">
          <Typography variant="h3">Schedule</Typography>
          <Grid container className="fmt-20" spacing={3}>
            <Grid item xs={6} className="py-0">
              <InputLabel id="frequency" style={{ fontSize: 13, fontWeight: 500 }}>
                Frequency
              </InputLabel>
              <Select
                IconComponent={DropDownIcon}
                input={
                  <OutlinedInput
                    classes={{
                      root: 'medium',
                      input: 'size_16_500 select-medium',
                    }}
                    name="frequency"
                  />
                }
                id="frequency"
                labelId="frequency"
                fullWidth
                value={frequency}
                name="frequency"
                onChange={handleChange}
                required
                displayEmpty
              >
                {Object.entries(FREQUENCY).map(([key, name]) => (
                  <MenuItem key={key} value={Number(key)}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} className="py-0 fmt-20" />
            <Grid item xs={6} className="py-0">
              <FormTextField label="Start Date & Time" fullWidth required>
                <DateField
                  value={start}
                  onChange={(date) => handleTimeChange(date, 'start')}
                  minDate={moment()}
                  error={error.startTime}
                  helperText={error.startTime}
                />
              </FormTextField>
            </Grid>
            <Grid item xs={6} className="py-0">
              <FormTextField label="End Date & Time" fullWidth required>
                <DateField
                  value={end}
                  onChange={(date) => handleTimeChange(date, 'end')}
                  minDate={start}
                  error={!!error.endTime}
                  helperText={error.endTime}
                  onReset={() => resetTimeField('end')}
                />
              </FormTextField>
            </Grid>
          </Grid>
        </Box>
        <Divider classes={{ root: 'height-2' }} style={{ backgroundColor: '#D8DCE0' }} />
        <Box className="fpx-20 fpt-5">
          <FormControlLabel
            name="primary-cta"
            control={<Checkbox color="primary" checked={primaryCta} onChange={handleCtaChange} />}
            label={
              <Typography variant="h3" className="font-500 d-flex align-items-center">
                Primary CTA
                <Tooltip title={PRIMARY_CTA_INFO} >
                  <InfoOutlinedIcon fontSize="small" className="ml-2" color="disabled" />
                </Tooltip>
              </Typography>
            }
          />
          {primaryCta && (
            <Grid container spacing={3} className="fmt-10 fpb-10">
              <Grid item xs={6} className="py-0">
                <FormTextField required label="CTA Text" fullWidth>
                  <TextField
                    id="cta-label-1"
                    variant="outlined"
                    value={ctaLabel1}
                    name="ctaLabel1"
                    onChange={handleChange}
                    error={!!error.ctaLabel1}
                    helperText={error.ctaLabel1}
                    InputProps={{
                      classes: {
                        root: 'medium',
                        input: 'size_16_500 medium',
                      },
                    }}
                  />
                </FormTextField>
              </Grid>
              <Grid item xs={6} className="py-0">
                <InputLabel id="cta-action-1" className="fmb-10" required style={{ fontSize: 13, fontWeight: 500 }}>
                  Action
                </InputLabel>
                <Select
                  IconComponent={DropDownIcon}
                  labelId="cta-action-1"
                  id="cta-action-1"
                  value={ctaAction1}
                  name="ctaAction1"
                  input={
                    <OutlinedInput
                      classes={{
                        root: 'medium',
                        input: 'size_16_500 select-medium',
                      }}
                      name="frequency"
                    />
                  }
                  fullWidth
                  onChange={handleActionChange}
                  required
                  displayEmpty
                  defaultValue="custom"
                >
                  {actions.map(({ key, name }) => (
                    <MenuItem key={key} value={key}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              {ctaAction1 === 'paymentPlan' && (
                <Grid item xs={12} className="py-0">
                  <FormTextField label="Opens" fullWidth required>
                    <Button
                      variant="outlined"
                      style={{
                        height: 48,
                        justifyContent: 'start',
                        ...(error.ctaOpen1 && { border: '1px solid #f44336' }),
                      }}
                      onClick={() => openPaymentDialog('ctaAction1')}
                      endIcon={<DropDownIcon style={{ fontSize: '24px' }} />}
                    >
                      <Typography style={{ width: '100%', textAlign: 'left' }}>
                        Payment Plans <ChevronRightRounded style={{ fontSize: 16 }} /> {ctaOpen1?.title}
                      </Typography>
                    </Button>
                    {error.ctaOpen1 && (
                      <Typography variant="subtitle1" color="error" style={{ fontWeight: 400, lineHeight: 1.66 }}>
                        {error.ctaOpen1}
                      </Typography>
                    )}
                  </FormTextField>
                </Grid>
              )}
              {ctaAction1 === 'challenges' && (
                <Grid item xs={12} className="py-0">
                  <FormTextField label="Opens" fullWidth required>
                    <Button
                      variant="outlined"
                      style={{
                        height: 48,
                        justifyContent: 'start',
                        ...(error.ctaOpen1 && { border: '1px solid #f44336' }),
                      }}
                      onClick={() => openChallengeDialog('ctaAction1')}
                      endIcon={<DropDownIcon style={{ fontSize: '24px' }} />}
                    >
                      <Typography style={{ width: '100%', textAlign: 'left' }}>
                        Challenge <ChevronRightRounded style={{ fontSize: 16 }} /> {ctaOpen1?.title}
                      </Typography>
                    </Button>
                    {error.ctaOpen1 && (
                      <Typography variant="subtitle1" color="error" style={{ fontWeight: 400, lineHeight: 1.66 }}>
                        {error.ctaOpen1}
                      </Typography>
                    )}
                  </FormTextField>
                </Grid>
              )}
              {ctaAction1 === 'custom' && (
                <Grid item xs={12} className="py-0">
                  <FormTextField label="Opens" fullWidth required>
                    <TextField
                      id="cta-open-1"
                      variant="outlined"
                      value={typeof ctaOpen1 === 'string' ? ctaOpen1 : ''}
                      name="ctaOpen1"
                      onChange={handleChange}
                      error={!!error.ctaOpen1}
                      helperText={error.ctaOpen1}
                      InputProps={{
                        classes: {
                          root: 'medium',
                          input: 'size_16_500 medium',
                        },
                      }}
                    />
                  </FormTextField>
                </Grid>
              )}
            </Grid>
          )}
        </Box>
        {primaryCta && <Divider classes={{ root: 'height-2' }} style={{ backgroundColor: '#D8DCE0' }} />}
        <Box className="fpx-20 fpt-5">
          <FormControlLabel
            name='secondary-cta'
            control={<Checkbox color="primary" onChange={handleCtaChange} checked={secondaryCta} />}
            label={
              <Typography variant="h3" className="font-500 d-flex align-items-center">
                Secondary CTA
                <Tooltip title={SECONDARY_CTA_INFO}>
                  <InfoOutlinedIcon fontSize="small" className="ml-2" color="disabled" />
                </Tooltip>
              </Typography>
            }
          />
          {secondaryCta && (
            <Grid container spacing={3} className="fmt-10">
              <Grid item xs={6} className="py-0">
                <FormTextField label="CTA Text" fullWidth>
                  <TextField
                    id="cta-label-2"
                    variant="outlined"
                    name="ctaLabel2"
                    value={ctaLabel2}
                    onChange={handleChange}
                    error={!!error.ctaLabel2}
                    helperText={error.ctaLabel2}
                    InputProps={{
                      classes: {
                        root: 'medium',
                        input: 'size_16_500 medium',
                      },
                    }}
                  />
                </FormTextField>
              </Grid>
              <Grid item xs={6} className="py-0">
                <InputLabel id="cta-action-2" className="fmb-10" style={{ fontSize: 13, fontWeight: 500 }}>
                  Action
                </InputLabel>
                <Select
                  IconComponent={DropDownIcon}
                  labelId="cta-action-2"
                  id="cta-action-2"
                  name="ctaAction2"
                  value={ctaAction2}
                  onChange={handleActionChange}
                  input={
                    <OutlinedInput
                      classes={{
                        root: 'medium',
                        input: 'size_16_500 select-medium',
                      }}
                      name="frequency"
                    />
                  }
                  fullWidth
                  required
                  displayEmpty
                  defaultValue="custom"
                >
                  {actions.map(({ key, name }) => (
                    <MenuItem key={key} value={key}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              {ctaAction2 === 'paymentPlan' && (
                <Grid item xs={12} className="py-0">
                  <FormTextField label="Opens" fullWidth>
                    <Button
                      variant="outlined"
                      style={{
                        height: 48,
                        justifyContent: 'start',
                        ...(error.ctaOpen2 && { border: '1px solid #f44336' }),
                      }}
                      onClick={() => openPaymentDialog('ctaAction2')}
                      endIcon={<DropDownIcon style={{ fontSize: '24px' }} />}
                    >
                      <Typography style={{ width: '100%', textAlign: 'left' }}>
                        Payment Plans <ChevronRightRounded style={{ fontSize: 16 }} /> {ctaOpen2?.title}
                      </Typography>
                    </Button>
                    {error.ctaOpen2 && (
                      <Typography variant="subtitle1" color="error" style={{ fontWeight: 400, lineHeight: 1.66 }}>
                        {error.ctaOpen2}
                      </Typography>
                    )}
                  </FormTextField>
                </Grid>
              )}
              {ctaAction2 === 'challenges' && (
                <Grid item xs={12} className="py-0">
                  <FormTextField label="Opens" fullWidth>
                    <Button
                      variant="outlined"
                      style={{
                        height: 48,
                        justifyContent: 'start',
                        ...(error.ctaOpen2 && { border: '1px solid #f44336' }),
                      }}
                      onClick={() => openChallengeDialog('ctaAction2')}
                      endIcon={<DropDownIcon style={{ fontSize: '24px' }} />}
                    >
                      <Typography style={{ width: '100%', textAlign: 'left' }}>
                        Challenge <ChevronRightRounded style={{ fontSize: 16 }} /> {ctaOpen2?.title}
                      </Typography>
                    </Button>
                    {error.ctaOpen2 && (
                      <Typography variant="subtitle1" color="error" style={{ fontWeight: 400, lineHeight: 1.66 }}>
                        {error.ctaOpen2}
                      </Typography>
                    )}
                  </FormTextField>
                </Grid>
              )}
              {ctaAction2 === 'custom' && (
                <Grid item xs={12} className="py-0">
                  <FormTextField label="Opens" fullWidth>
                    <TextField
                      id="cta-open-2"
                      variant="outlined"
                      value={typeof ctaOpen2 === 'string' ? ctaOpen2 : ''}
                      name="ctaOpen2"
                      error={!!error.ctaOpen2}
                      helperText={error.ctaOpen2}
                      onChange={handleChange}
                      InputProps={{
                        classes: {
                          root: 'medium',
                          input: 'size_16_500 medium',
                        },
                      }}
                    />
                  </FormTextField>
                </Grid>
              )}
            </Grid>
          )}
        </Box>
      </Dialog>
        <CatalogSingleSelector
          title="Select challenge"
          open={challengeDialog.open}
          handleAdd={(doc) => handleChallengeAdd(doc[0])}
          onClose={() => setChallengeDialog(DEFAULT_DIALOG)}
          keyName="challenges"
          defaultFilter={{ status: ['active'] }}
          today={moment().local().format('YYYYMMDD')}
          isFilter={false}
        />
      {paymentDialog.open && (
        <PaymentPlanSelector onSubmit={handlePlanSubmit} onClose={() => setPaymentDialog(DEFAULT_DIALOG)} />
      )}
    </>
  );
}

const Arr = React.memo(({ value, onReset }) => {
  function handleClick(e) {
    e.stopPropagation();
    onReset();
  }

  if (onReset && value !== '-') {
    return (
      <InputAdornment position="end">
        <IconButton size="small" onClick={handleClick}>
          <CloseRounded />
        </IconButton>
      </InputAdornment>
    );
  }
  return (
    <InputAdornment position="end">
      <ArrowIcon color="action" />
    </InputAdornment>
  );
});

const PickerField = (props) => {
  const { value, onReset } = props;
  return (
    <TextField {...props} className="standard" InputProps={{ endAdornment: <Arr onReset={onReset} value={value} /> }} />
  );
};
const DateField = (props) => {
  const { onChange, value, minDate, onReset, error, helperText } = props;
  return (
    <DateTimePicker
      autoOk
      value={value}
      inputVariant="outlined"
      onChange={onChange}
      format={value ? 'MMM DD, h:mm a' : '-'}
      TextFieldComponent={(props) => <PickerField onReset={onReset} {...props} />}
      minDate={minDate}
      helperText={helperText}
      error={error}
    />
  );
};

export default InAppNotificationDialog;
